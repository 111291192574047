import React from "react"
import { Heading, Pane, Paragraph, TickCircleIcon } from "evergreen-ui"
import { useTranslation } from "react-i18next"

const ThankYouPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <Pane display="flex" padding="2rem" justifyContent="center">
        <div style={{ textAlign: "center" }}>
          <TickCircleIcon color="success" size={40} />
          <Heading size={700}>{t("Thank you for registering")}</Heading>
          <Paragraph>
            {t("We will get in contact with you as soon as possible")}
          </Paragraph>
        </div>
      </Pane>
    </>
  )
}

export default ThankYouPage
